import React from "react";
import { Link } from "gatsby";
import Image from "~/components/elements/Image";
import { Icon } from "~/components/elements/Icon";
import clsx from "clsx";

export const WhitepapersPodcastsPreviewsSection = (props) => {
	const { posts, largeImages } = props;

	return (
		<div className="grid grid-cols-2 gap-6 lg:grid-cols-4">
			{posts.slice(0, 4).map((post, i) => (
				<Link key={`whitepaper-podcast-${i}`} to={post.uri} className="group space-y-8">
					{/* Image */}
					<div
						style={{ aspectRatio: largeImages ? "281/329" : "282/211" }}
						className={clsx(largeImages ? "max-w-[281px]" : "max-w-[281px]", "aspect-w-4x aspect-h-3x overflow-hidden")}
					>
						<Image
							image={post?.article?.image}
							className="!absolutex h-full w-full transform transition-transform duration-700 ease-in-out group-hover:scale-110"
							objectFit="cover"
						/>
					</div>

					<div className="space-y-3.5 transition-opacity duration-300 ease-in-out group-hover:opacity-50">
						<div className="text-sm capitalize text-purple">{post?.article?.type}</div>
						<div className="emd:text-[21px] text-[16px] leading-[1.25]">
							{post.title}{" "}
							<div className="inline-block">
								<Icon type="arrowRightDown" />
							</div>
						</div>
					</div>
				</Link>
			))}
		</div>
	);
};

export default WhitepapersPodcastsPreviewsSection;
