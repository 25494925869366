import React from "react";
import { Link } from "gatsby";
import Image from "~/components/elements/Image";
import { Icon, IconType } from "~/components/elements/Icon";
import clsx from "clsx";

export const VideosPreviewsSection = (props) => {
	const { posts } = props;

	return (
		<div className="flex flex-col divide-y divide-purple-2">
			{[...posts]?.slice(0, 3)?.map((post, i) => (
				<Link key={`video-preview-${i}`} to={post.uri} className={clsx(i === 0 && "!pt-0", i === 2 && "!pb-0", "group grid py-[62px] md:grid-cols-2")}>
					{/* Image */}
					<div className="aspect-h-2 aspect-w-3 overflow-hidden">
						<Image
							className="!absolute w-full transform transition-transform duration-700 ease-in-out group-hover:scale-110"
							image={post.article.image}
							objectFit="cover"
						/>
					</div>

					{/* Content */}
					<div className="mt-6 space-y-6 md:mt-12 md:space-y-10 lg:mt-0 lg:pl-24 lg:pr-16">
						<div className="max-w-[420px] space-y-3.5 transition-opacity duration-300 ease-in-out group-hover:opacity-60">
							{/* Article type */}
							<div className="text-sm capitalize text-purple">{post?.article?.type}</div>

							{/* Title */}
							<div className="text-[32px] leading-[1.05]">
								{post.title}{" "}
								<div className="inline-block">
									<Icon type="arrowRightDown" />
								</div>
							</div>
						</div>

						{/* Watch time */}
						{post?.article?.readwatchlistenTime && (
							<div className="flex items-center">
								<div className="fw-icon h-4 w-4 text-grey-2">
									<Icon type={IconType.Clock} />
								</div>

								<span className="ml-2.5 block leading-[1.0]">{post.article.readwatchlistenTime}</span>
							</div>
						)}
					</div>
				</Link>
			))}
		</div>
	);
};

export default VideosPreviewsSection;
