import { graphql, useStaticQuery } from "gatsby";

const useCategories = () => {
	const data = useStaticQuery(graphql`
		query {
			allWpCategory(filter: { name: { nin: ["Uncategorized", "Uncategorised"] } }) {
				nodes {
					id
					name
					uri
					description
					category {
						layout
						sectionHeading
						icon
						heroColour
						heroIllustration {
							...Image
						}
					}
					posts {
						... on WpCategoryToPostConnection {
							nodes {
								date
								dateGmt
								id
								title
								uri
								article {
									readwatchlistenTime
									quote {
										company
										name
										text
									}
									image {
										...Image
									}
									type
									summary
								}
							}
						}
					}
				}
			}
		}
	`);

	return data?.allWpCategory;
};

export default useCategories;
