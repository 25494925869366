import React from "react";
import { Link } from "gatsby";
import clsx from "clsx";
import { Button, ButtonType } from "../elements/buttons/Button";
import Image from "../elements/Image";
import { IconType } from "../elements/Icon";

const CaseStudyPreviewRow = (props) => {
	const { post, reverseLayout } = props;
	const { article } = post;

	return (
		<div className="case-study-preview-row group">
			<Link className="group" to={post.uri}>
				<div className={clsx("flex flex-col-reverse gap-8", reverseLayout ? "md:flex-row-reverse" : "md:flex-row")}>
					<div className="flex flex-col justify-between gap-10 border-t border-purple-2 pt-6 md:gap-20">
						{/* Quote */}
						<div className="flex gap-6">
							{/* Quote mark */}
							<span className="flex-shrink-0">
								<QuoteMark />
							</span>

							{/* Quote */}
							<div className="flex flex-col gap-4">
								{/* Text */}
								<span className="text-30 block font-heading !leading-[1.10]" dangerouslySetInnerHTML={{ __html: article?.quote?.text }} />
								{/* Name, company */}
								<span
									className="text-16 block !font-normal opacity-70"
									dangerouslySetInnerHTML={{ __html: `${article?.quote?.name}${!!article?.quote?.company ? "," : ""} ${article?.quote?.company}` }}
								/>
							</div>
						</div>

						{/* Title, CTA */}
						<div className="flex flex-col gap-4 md:gap-8">
							{/* Title */}
							<span
								className="text-21 !font-medium transition-opacity duration-300 ease-out group-hover:opacity-60"
								dangerouslySetInnerHTML={{ __html: post?.title }}
							/>

							{/* CTA */}
							<Button noLink link={{ url: article.uri, title: "Learn more" }} icon={IconType.ArrowRightDown} type={ButtonType.Text} />
						</div>
					</div>

					{/* Image */}
					<div style={{ aspectRatio: "768/498" }} className="w-full flex-shrink-0 overflow-hidden rounded-2xl md:max-w-[50%] xl:max-w-[768px]">
						<Image className="h-full w-full transition-transform duration-500 ease-out group-hover:scale-105" image={article.image} objectFit="cover" />
					</div>
				</div>
			</Link>
		</div>
	);
};

const QuoteMark = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="27" height="20" viewBox="0 0 27 20" fill="none">
			<path
				d="M19.2857 9.03766H22.3882C24.8199 9.53975 26.7484 11.2971 26.7484 14.3096C26.7484 17.7406 24.1491 20 20.795 20C16.7702 20 14.4224 16.7364 14.4224 12.2176C14.4224 5.52301 18.8665 0.585772 27 0V2.25941C22.3882 3.01255 19.6211 5.35565 19.2857 9.03766ZM4.7795 9.03766H7.96584C10.3975 9.53975 12.3261 11.2971 12.3261 14.3096C12.3261 17.7406 9.64286 20 6.37267 20C2.34783 20 0 16.7364 0 12.2176C0 5.52301 4.4441 0.585772 12.5776 0V2.25941C7.88199 3.01255 5.19876 5.35565 4.7795 9.03766Z"
				fill="#6360D8"
			/>
		</svg>
	);
};

export default CaseStudyPreviewRow;
