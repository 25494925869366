import React from "react";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import { Section } from "~/components/elements/Section";
import { Button, ButtonType } from "~/components/elements/buttons/Button";

import { Icon, IconType } from "~/components/elements/Icon";
import CaseStudiesPreviewsSection from "./CaseStudiesPreviewsSection";
import ArticlesPreviewsSection from "./ArticlesPreviewsSection";
import VideosPreviewsSection from "./VideosPreviewsSection";
import WhitepapersPodcastsPreviewsSection from "./WhitepapersPodcastsPreviewsSection";

export default function Resources(props) {
	const {
		name,
		uri,
		posts,
		category,
		description,
		hideButton = false,
		siteSearchIndex,
		featured = false,
		last = false,
		noLimit = false,
		addCaseStudiesGrid = false,
	} = props;

	const length = posts?.nodes?.length || posts?.length;

	const link = {
		title: "View all " + name,
		url: uri,
	};

	if (length === undefined || length === 0) return null;

	return (
		<Section
			paddingTop="144"
			paddingBottom="80"
			backgroundColour={["case-studies", "articles"].includes(category.layout) && `dark-purple`}
			classname={`${(category.layout === "articles" || category.layout === "video") && `max-w-none`} ${last && "!pb-0"} `}
		>
			<div className="container space-y-20 xl:pb-16">
				<div className="space-y-12">
					{/* Heading, count */}
					<div className="flex flex-col space-y-4 border-b border-b-purple pb-12 md:flex-row md:items-end">
						{/* Heading */}
						<Heading as="h2" size={HeadingSizeOption.h2} text={category.sectionHeading} />

						{/* Count */}
						<div className="text-[26px] md:ml-auto">
							<div className="inline-block font-semibold">{length}</div> {name}
						</div>
					</div>

					{/* Preview layouts */}
					{category.layout === "case-studies" && <CaseStudiesPreviewsSection posts={posts.nodes || posts} name={name} addGrid={addCaseStudiesGrid} />}
					{category.layout === "articles" && <ArticlesPreviewsSection posts={posts.nodes || posts} name={name} featured={featured} noLimit={noLimit} />}
					{category.layout === "video" && <VideosPreviewsSection posts={posts.nodes || posts} name={name} />}
					{category.layout === "whitepapers-podcasts" && (
						<WhitepapersPodcastsPreviewsSection posts={posts.nodes || posts} name={name} largeImages={name === "Whitepapers"} />
					)}
				</div>

				{/* CTA */}
				{!hideButton && (
					<div className="flex items-center justify-center">
						<div className="hidden h-px bg-purple-2 md:block md:w-1/3"></div>
						<div className="w-aut flex justify-center md:w-1/3">
							<Button
								type={ButtonType.Button}
								link={link}
								icon={IconType.ArrowRightDown}
								hoverBackgroundColor={["case-studies", "whitepapers-podcasts"].includes(category.layout) ? "white" : "black"}
							/>
						</div>
						<div className="hidden h-px bg-purple-2 md:block md:w-1/3"></div>
					</div>
				)}
			</div>
		</Section>
	);
}
