import { graphql } from "gatsby";
import React from "react";
import Layout from "~/components/Layout";
import useCategories from "~/hooks/useCategories";

import Resources from "~/components/flexible/Resources/Resources";
import { WpPage_Page_FlexibleContent } from "~/_generated/types";
import { useSearch } from "~/hooks/useSearch";
import SearchResults from "~/components/global/SearchResults";
import useBlogPosts from "~/hooks/useBlogPosts";
import Seo from "gatsby-plugin-wpgraphql-seo";

const getSortedCategories = (categories) => {
	const orderMap = {
		0: "Articles",
		1: "Videos",
		2: "Case Studies",
		3: "Whitepapers",
		4: "Podcasts",
	};

	// Convert orderMap to a reverse lookup map for easier sorting
	const reverseOrderMap = Object.fromEntries(Object.entries(orderMap).map(([key, value]) => [value, parseInt(key)]));

	// Sort items based on the order in reverseOrderMap
	return categories.sort((a, b) => {
		return reverseOrderMap[a.name] - reverseOrderMap[b.name];
	});
};

export default function ResourcesPage({
	data: { wpPage, wp, siteSearchIndex },
}: {
	data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
	const { nodes } = useCategories();
	const categories = nodes;

	const sortedCategories = getSortedCategories(categories);

	console.log("sortedCategories", sortedCategories);

	const [query, setQuery] = React.useState("");

	const { allWpPost } = useBlogPosts();

	const results = useSearch(siteSearchIndex.index, query);

	const resourceResults = results.filter(({ nodeType }) => nodeType === "Post").map(({ slug }) => allWpPost.nodes.find((post) => post.slug === slug));

	return (
		<Layout wp={wp} showResourcesHeader activeSearchQuery={query} setQuery={setQuery}>
			<Seo post={wpPage} />

			<div className="pt-[140px] lg:pt-[200px]">
				{query?.trim().length > 0 ? (
					<SearchResults results={resourceResults} query={query} />
				) : (
					sortedCategories?.length > 0 &&
					sortedCategories?.map((category, i) => {
						// console.log('category', category);

						return <Resources key={category.id} {...category} featured last={i === categories.length - 1} />;
					})
				)}

				{/* <FlexibleContent content={wpPage?.page?.flexibleContent} /> */}
			</div>
		</Layout>
	);
}

export const pageQuery = graphql`
	query Resources($id: String!) {
		wpPage(id: { eq: $id }) {
			...SEO
			...GeneratedWpPage
		}
		wp {
			...GeneratedWp
		}

		siteSearchIndex {
			index
		}
	}
`;
