import { Link } from "gatsby";
import React from "react";
import Image from "~/components/elements/Image";
import { Button, ButtonType } from "~/components/elements/buttons/Button";
import { Icon, IconType } from "~/components/elements/Icon";

const ArticlesPreviewsSection = ({ featured = false, posts, allPosts = false, name, noLimit = false }) => {
	let featuredPost = null;
	let sliceStart = 0;
	let sliceEnd = 3;

	if (featured) {
		featuredPost = posts[0];
		sliceStart = 1;
		sliceEnd = noLimit ? 100 : 10;
	}

	if (allPosts) {
		sliceStart = 0;
		sliceEnd = noLimit ? 100 : 10;
	}

	return (
		<>
			{featured && featuredPost && (
				<Link to={featuredPost.uri} className="group grid space-y-12 lg:grid-cols-2 lg:space-y-0">
					<div className="">
						<div className="aspect-h-3 aspect-w-4 overflow-hidden lg:aspect-h-2 lg:aspect-w-3">
							<Image
								className="!absolute w-full transform transition-transform duration-700 ease-in-out group-hover:scale-110"
								image={featuredPost.article.image}
								objectFit="cover"
							/>
						</div>
					</div>
					<div className="flex flex-col transition-opacity duration-300 ease-in-out group-hover:opacity-60 lg:pl-24 lg:pr-16">
						<div className="space-y-3.5">
							<div className="text-sm capitalize text-purple">{featuredPost.article.type}</div>
							<div className="text-[32px] leading-[1.05]">{featuredPost.title}</div>
						</div>
						<div className="mt-6 lg:mt-auto">
							<Button noLink link={{ url: featuredPost.uri, title: "Read Article" }} icon={IconType.ArrowRightDown} type={ButtonType.Text} />
						</div>
					</div>
				</Link>
			)}

			<div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 border-t border-t-purple pt-12 sm:grid-cols-2 md:grid-cols-3">
				{posts.slice(sliceStart, sliceEnd).map((post, i) => (
					<Link key={`article-${i}`} to={post.uri} className="group space-y-5 md:space-y-8">
						<div className="aspect-h-3 aspect-w-4 overflow-hidden">
							<Image
								image={post?.article?.image}
								className="!absolute transform transition-transform duration-700 ease-in-out group-hover:scale-110"
								objectFit="cover"
							/>
						</div>

						<div className="space-y-1 transition-opacity duration-300 ease-in-out group-hover:opacity-60 md:space-y-3.5">
							<div className="text-sm capitalize text-purple">{post?.article?.type}</div>
							<div className="text-20 !font-medium leading-[1.25]">
								{post.title}{" "}
								<div className="ml-3 inline-block">
									<Icon type="arrowRightDown" />
								</div>
							</div>
						</div>
					</Link>
				))}
			</div>
		</>
	);
};

export default ArticlesPreviewsSection;
