import React from "react";
import Image from "../elements/Image";
import { Link } from "gatsby";
import { IconType } from "../elements/Icon";
import { Button, ButtonType } from "../elements/buttons/Button";

const CaseStudyPreviewCard = (props) => {
	const { post } = props;
	const { article } = post;

	return (
		<div className="case-study-preview-card border-t border-purple-2 pt-8 md:pt-12">
			<Link className="group" to={post.uri}>
				<div className="flex flex-col items-start gap-6 md:gap-8">
					{/* Image */}
					{!!article?.image && (
						<div style={{ aspectRatio: "560/308" }} className="w-full flex-shrink-0 overflow-hidden rounded-2xl xl:max-w-[768px]">
							<Image className="h-full w-full transition-transform duration-500 ease-out group-hover:scale-105" image={article.image} objectFit="cover" />
						</div>
					)}

					{/* Title */}
					{!!post?.title && (
						<span
							className="text-24 !font-medium !tracking-[-0.02em] transition-opacity duration-300 ease-out group-hover:opacity-60"
							dangerouslySetInnerHTML={{ __html: post?.title }}
						/>
					)}

					{/* CTA */}
					<Button noLink link={{ url: article.uri, title: "Learn more" }} icon={IconType.ArrowRightDown} type={ButtonType.Text} />
				</div>
			</Link>
		</div>
	);
};

export default CaseStudyPreviewCard;
