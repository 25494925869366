import clsx from "clsx";
import { Link } from "gatsby";
import React from "react";
import { Button, ButtonType } from "~/components/elements/buttons/Button";
import { IconType } from "~/components/elements/Icon";
import Image from "~/components/elements/Image";
import CaseStudyPreviewCard from "~/components/previews/CaseStudyPreviewCard";
import CaseStudyPreviewRow from "~/components/previews/CaseStudyPreviewRow";

const CaseStudiesPreviewsSection = (props) => {
	const { posts, addGrid } = props;

	const gridPosts = posts?.slice(1, posts?.length) || [];

	return (
		<div className="case-studies-preview-section">
			{!addGrid ? (
				<div className="flex flex-col gap-20 md:pt-12 lg:gap-[128px]">
					{!!posts?.[0] && <CaseStudyPreviewRow post={posts?.[0] || {}} reverseLayout={false} />}
					{!!posts?.[1] && <CaseStudyPreviewRow post={posts?.[1] || {}} reverseLayout />}
				</div>
			) : (
				<div className="flex flex-col gap-20 md:pt-12 lg:gap-[128px]">
					<CaseStudyPreviewRow post={posts?.[0] || {}} reverseLayout={false} />

					{gridPosts?.length > 0 && (
						<div className="grid grid-cols-1 gap-14 md:grid-cols-2 lg:gap-20">
							{gridPosts?.map((post, index) => (
								<CaseStudyPreviewCard key={`case-studies-grid-post-${index}`} post={post} />
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default CaseStudiesPreviewsSection;
